// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".edit-pet_editPet_ddf6c510 {\n  width: 100%;\n}\n\n.edit-pet_speciesChoice_ec39a8c1 {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  margin: 32px 0 0;\n  justify-content: space-between;\n  gap: 16px;\n}\n\n.edit-pet_speciesChoice_ec39a8c1 button {\n  flex: 1;\n}\n\n.edit-pet_speciesChoiceError_5caaf8b8 {\n  margin: 40px 0 12px;\n}\n\n.edit-pet_editPet_ddf6c510 .edit-pet_textfield_6161cf0c {\n  width: 100%;\n  margin: 0;\n  padding-bottom: 0;\n}\n\n.edit-pet_editPet_ddf6c510 .edit-pet_errorText_f4574c04 {\n  color: var(--cliffordRed);\n  text-align: left;\n  margin: 8px 0 0 ;\n}\n", "",{"version":3,"sources":["webpack://src/quotes/view/introduction/edit-pet/edit-pet.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,8BAA8B;EAC9B,SAAS;AACX;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,SAAS;EACT,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".editPet {\n  width: 100%;\n}\n\n.speciesChoice {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  margin: 32px 0 0;\n  justify-content: space-between;\n  gap: 16px;\n}\n\n.speciesChoice button {\n  flex: 1;\n}\n\n.speciesChoiceError {\n  margin: 40px 0 12px;\n}\n\n.editPet .textfield {\n  width: 100%;\n  margin: 0;\n  padding-bottom: 0;\n}\n\n.editPet .errorText {\n  color: var(--cliffordRed);\n  text-align: left;\n  margin: 8px 0 0 ;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editPet": "edit-pet_editPet_ddf6c510",
	"speciesChoice": "edit-pet_speciesChoice_ec39a8c1",
	"speciesChoiceError": "edit-pet_speciesChoiceError_5caaf8b8",
	"textfield": "edit-pet_textfield_6161cf0c",
	"errorText": "edit-pet_errorText_f4574c04"
};
export default ___CSS_LOADER_EXPORT___;
