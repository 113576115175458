// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pet-info_breedSelection_eaaf5540,\n.pet-info_ageSelection_6cf78454 {\n  width: 100%;\n}\n\n.pet-info_genderChoice_67e8b2c3 {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  margin-top: 40px;\n  justify-content: space-between;\n  gap: 16px;\n}\n\n.pet-info_genderChoice_67e8b2c3 button {\n  flex: 1;\n}\n\n.pet-info_breedSelection_eaaf5540 {\n  margin-top: 24px;\n  margin-bottom: 40px;\n}\n\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["downXs"] + " {\n  .pet-info_genderChoice_67e8b2c3 {\n    margin-top: 24px;\n  }\n\n  .pet-info_breedSelection_eaaf5540 {\n    margin-bottom: 32px;\n  }\n\n  .pet-info_ageSelection_6cf78454 {\n    margin-bottom: 16px;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/quotes/view/pet-info/pet-info.css"],"names":[],"mappings":"AAEA;;EAEE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,8BAA8B;EAC9B,SAAS;AACX;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;;AAGA;EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;AACF","sourcesContent":["@value downXs from '~@pumpkincare/shared/ui/breakpoints';\n\n.breedSelection,\n.ageSelection {\n  width: 100%;\n}\n\n.genderChoice {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  margin-top: 40px;\n  justify-content: space-between;\n  gap: 16px;\n}\n\n.genderChoice button {\n  flex: 1;\n}\n\n.breedSelection {\n  margin-top: 24px;\n  margin-bottom: 40px;\n}\n\n\n@media downXs {\n  .genderChoice {\n    margin-top: 24px;\n  }\n\n  .breedSelection {\n    margin-bottom: 32px;\n  }\n\n  .ageSelection {\n    margin-bottom: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"downXs": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["downXs"] + "",
	"breedSelection": "pet-info_breedSelection_eaaf5540",
	"ageSelection": "pet-info_ageSelection_6cf78454",
	"genderChoice": "pet-info_genderChoice_67e8b2c3"
};
export default ___CSS_LOADER_EXPORT___;
