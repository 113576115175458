import { useState } from 'react';
import classNames from 'classnames';

import { validateName } from '@pumpkincare/quotes';
import { CAT, DOG } from '@pumpkincare/shared';
import { Chip, TextField, Typography } from '@pumpkincare/shared/ui';

import styles from './edit-pet.css';

export default function EditPet({ pet, onChange }) {
  const [errorMessage, setErrorMessage] = useState(null);
  const speciesChoiceClassName = classNames(styles.speciesChoice, {
    [styles.speciesChoiceError]: !!errorMessage,
  });

  function handleSpeciesChange(species) {
    onChange({ ...pet, petBreedSpecies: species });
  }

  function handleNameChange({ target: { value } }) {
    if (!validateName(value)) {
      setErrorMessage('Please enter a valid name using only letters');
    } else {
      setErrorMessage(null);
    }

    onChange({ ...pet, petName: value });
  }

  return (
    <div className={styles.editPet}>
      <TextField
        autoFocus
        key={pet.id}
        classes={{ container: styles.textfield }}
        label={`Pet Name`}
        value={pet.petName}
        data-testid={'pet-name-input'}
        placeholder='Pet Name'
        onChange={handleNameChange}
      />

      <div className={speciesChoiceClassName}>
        <Chip
          label={DOG}
          icon={'/assets/images/iconImages/dog.png'}
          checked={pet.petBreedSpecies === DOG}
          onClick={() => handleSpeciesChange(DOG)}
          data-testid={'dog-button'}
          role={'radio'}
        />

        <Chip
          label={CAT}
          icon={'/assets/images/iconImages/cat.png'}
          checked={pet.petBreedSpecies === CAT}
          onClick={() => handleSpeciesChange(CAT)}
          data-testid={'cat-button'}
          role={'radio'}
        />
      </div>

      {errorMessage ? (
        <p
          className={classNames(
            Typography.legalBody,
            Typography.bold,
            styles.errorText
          )}
        >
          {errorMessage}
        </p>
      ) : null}
    </div>
  );
}
