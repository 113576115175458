import { useState } from 'react';
import classNames from 'classnames';

import { analyticsTrack, INITIATE_EXPERIMENT } from '@pumpkincare/analytics';
import { useBooleanInput, usePaperlessAgreement } from '@pumpkincare/shared';
import {
  Body2,
  Checkbox,
  LegalBody,
  SwitchInput,
  Typography,
} from '@pumpkincare/shared/ui';

import {
  useErrors,
  useErrorsVisibility,
  usePaperLessSetter,
} from '../../../checkout-provider';

import styles from './paperless.css';

function Paperless() {
  const [isOpt, toggleOpt] = useBooleanInput(true);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const {
    data: { paperless_terms_url, terms_version },
  } = usePaperlessAgreement();

  const [errorsVisibility, setErrorsVisibility] = useErrorsVisibility();
  const [checkBoxVisibility, setCheckboxVisibility] = useState(true);

  const setPaperLess = usePaperLessSetter();
  const errors = useErrors();

  const isErrorState = errors.current.paperLessChecked && errorsVisibility;

  function handleAgreementChange() {
    setIsTermsAccepted(!isTermsAccepted);
    setErrorsVisibility(false);

    setPaperLess({
      termsVersion: !isTermsAccepted === true ? terms_version : null,
      isPaperLess: !isTermsAccepted,
    });

    if (isOpt) {
      checkErrors();
    }
  }
  function handleOptChange(isChecked) {
    toggleOpt();

    setCheckboxVisibility(!!isChecked);

    if (!isChecked) {
      delete errors.current.paperLessChecked;
      setErrorsVisibility(false);
      setIsTermsAccepted(false);
      setPaperLess({
        termsVersion: null,
        isPaperLess: false,
      });
    } else {
      checkErrors();

      setPaperLess({
        termsVersion: terms_version,
        isPaperLess: isChecked,
      });
    }

    analyticsTrack({
      event: INITIATE_EXPERIMENT,
      category: 'Paperless',
      label: isChecked ? 'opt_in' : 'opt_out',
    });
  }

  function checkErrors() {
    if (errors.current.paperLessChecked) {
      delete errors.current.paperLessChecked;
    } else {
      errors.current = {
        ...errors.current,
        paperLessChecked:
          'Looks like you forgot to agree to the paperless consent agreement',
      };
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.headerSection}>
          <img src='/assets/images/iconImages/plant.svg' alt='' />

          <div className={styles.title}>
            <h4 className={Typography.h4}>Go Paperless with Pumpkin.</h4>

            <div className={styles.check}>
              <SwitchInput
                checked={isOpt}
                onChange={handleOptChange}
                id='toggleOpt'
              />

              <Body2 isBold className={styles.optText}>
                Opt {isOpt ? 'In' : 'Out'}
              </Body2>
            </div>
          </div>
        </div>

        <Body2>
          Now the environmentally-friendly option is also the most convenient option
          for you to manage your Pumpkin account. Skip the paper – see all documents
          online!
        </Body2>

        {checkBoxVisibility ? (
          <div className={styles.footerSection}>
            <Checkbox
              onChange={handleAgreementChange}
              classes={{ root: styles.checkboxRoot }}
              checked={isTermsAccepted}
              label={
                <>
                  I have read{' '}
                  <a
                    href={paperless_terms_url}
                    className={classNames(Typography.link, styles.link)}
                    target={'_blank'}
                    rel='noreferrer'
                  >
                    Go Paperless with Pumpkin Consent Agreement
                  </a>{' '}
                  and understand that I will no longer receive documents in the mail.
                </>
              }
              data-testid={'accept-paperless-checkbox'}
            />
          </div>
        ) : null}

        {isErrorState ? (
          <LegalBody className={styles.error}>
            &nbsp;{errors.current.paperLessChecked}
          </LegalBody>
        ) : null}
      </div>
    </div>
  );
}

export default Paperless;
