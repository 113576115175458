import { Chip } from '@pumpkincare/shared/ui';

import styles from './yes-no.css';

function YesNo({
  data,
  disabled = false,
  onChange = () => {},
  currentAnswer,
  autoFocus = false,
}) {
  const currentId = currentAnswer[0]?.id;

  return (
    <div role={'radiogroup'} className={styles.group}>
      {data.map((answer, i) => {
        const { answer_text = '', id } = answer;
        const isSelected = id === currentId;

        return (
          <Chip
            classes={{ root: styles.groupItem }}
            disabled={disabled}
            label={answer_text}
            checked={isSelected}
            onClick={() => onChange(answer)}
            role={'radio'}
            tabIndex={i + 1}
            key={id}
            direction={'v'}
            data-testid='radio'
            autoFocus={autoFocus && i === 0}
          />
        );
      })}
    </div>
  );
}

export default YesNo;
